import {
  SiCplusplus,
  SiJavascript,
  SiDjango,
  SiSass,
  SiAdobecreativecloud,
  SiMicrosoftoffice,
  SiNextdotjs,
  SiTailwindcss,
  SiFirebase,
} from "react-icons/si";
import { FaPython, FaReact } from "react-icons/fa";
import { AiOutlineConsoleSql, AiFillHtml5, AiFillGithub } from "react-icons/ai";
import { DiCss3, DiMongodb, DiMysql, DiLinux } from "react-icons/di";
import { BiGitBranch } from "react-icons/bi";

import att_icon from "../assets/skill_icons/attention_icon.svg";
import comm_icon from "../assets/skill_icons/comm_icon.svg";
import dm_icon from "../assets/skill_icons/dm_icon.svg";
import motivation_icon from "../assets/skill_icons/motivation_icon.svg";
import tm_icon from "../assets/skill_icons/tm_icon.svg";

export const HSKILLONE = [
  {
    icon: SiCplusplus,
    name: "C++",
  },
  {
    icon: FaPython,
    name: "Python",
  },
  {
    icon: SiJavascript,
    name: "Javascript",
  },
  {
    icon: DiMysql,
    name: "SQL",
  },
];

export const HSKILLTWO = [
  {
    icon: FaReact,
    name: "ReactJs",
  },
  {
    icon: SiNextdotjs,
    name: "NextJs",
  },
  {
    icon: SiDjango,
    name: "Django",
  },
];

export const HSKILLTHREE = [
  {
    icon: AiFillHtml5,
    name: "HTML",
  },
  {
    icon: DiCss3,
    name: "CSS",
  },
  {
    icon: SiSass,
    name: "SCSS",
  },
  {
    icon: SiTailwindcss,
    name: "Tailwindcss",
  },
];

export const HSKILLFOUR = [
  {
    icon: DiMongodb,
    name: "MongoDB",
  },
  {
    icon: AiOutlineConsoleSql,
    name: "MySQL",
  },
  {
    icon: SiFirebase,
    name: "Firebase",
  },
];

export const HSKILLFIVE = [
  {
    icon: BiGitBranch,
    name: "Git",
  },
  {
    icon: AiFillGithub,
    name: "Github",
  },
];

export const HSKILLSIX = [
  {
    icon: DiLinux,
    name: "Linux",
  },
  {
    icon: SiMicrosoftoffice,
    name: "Microsoft Office",
  },
  {
    icon: SiAdobecreativecloud,
    name: "Adobe Creative Cloud",
  },
];

export const SOFTSKILLS = [
  {
    icon: att_icon,
    name: "Attention to Detail",
  },
  {
    icon: comm_icon,
    name: "Communication",
  },
  {
    icon: dm_icon,
    name: "Decision Making",
  },
  {
    icon: motivation_icon,
    name: "Self-motivation",
  },
  {
    icon: tm_icon,
    name: "Time Management",
  },
];
